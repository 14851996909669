import {createContext, useCallback, useContext, useEffect, useState} from "react";

type PrincipalContextType = {
    username: string,
    fullName: string,
    email: string,
}
const PrincipalContext = createContext<PrincipalContextType>({
    "username": "",
    "fullName": "",
    "email": "",
});

const {Provider} = PrincipalContext;

function PrincipalProvider({children}: { children: React.ReactNode }) {
    const [username, setUsername] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");

    const fetchUsername = useCallback(async () => {
        try {
            const result = await fetch("/api/principal");
            const data = await result.json();
            return {
                username: data.name.split(" ")[0],
                fullName: data.name,
                email: data.email,
            };
        } catch (e) {
            console.error(e);
        }

        return undefined;
    }, []);

    useEffect(() => {
        fetchUsername().then((data) => {
            if (data) {
                setUsername(data.username);
                setFullName(data.fullName);
                setEmail(data.email);
            }
        });
    }, [fetchUsername]);

    return (
        <Provider value={{username, fullName, email}}>
            {children}
        </Provider>
    );
}

const usePrincipalContext = () => useContext(PrincipalContext);

export {PrincipalProvider, usePrincipalContext}
