import React from "react";
import classNames from "classnames";
import { ThemeProvider } from "@rescui/ui-contexts";
import { createTextCn } from "@rescui/typography";
import {
  CodeGolfIcon,
  DiamondIcon,
  EmployeeIcon,
  IdeaIcon,
  OfferIcon,
  RocketIcon,
} from "@webteam/icons-big";

import s from "./instructions.module.scss";

import offers from "./offers.json";
import rules from "./rules.json";

const textCn = createTextCn("dark");

const icons = [
  OfferIcon,
  EmployeeIcon,
  DiamondIcon,
  IdeaIcon,
  RocketIcon,
  CodeGolfIcon,
];

export default function Instructions() {
  return (
    <ThemeProvider theme="dark">
      <div className={classNames(s.block, "container")}>
        <h3 className={classNames(s.title, textCn("rs-h3"))}>
          What JetCamp offers
        </h3>
        <div className={s.wrapper}>
          {offers.map((offer, index) => {
            const Element = icons[index];
            return (
              <div className={s.item} key={index}>
                <Element className={s.icon} />
                <h5 className={classNames(s["item-title"], textCn("rs-h5"))}>
                  {offer.title}
                </h5>
                <p className={textCn("rs-text-3")}>{offer.description}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classNames(s.block, "container")}>
        <h3 className={classNames(s.title, textCn("rs-h3"))}>General Rules</h3>
        <div className={s.wrapper}>
          {rules.map((rule, index) => (
            <div className={s.item} key={index}>
              <h5 className={classNames(s["item-title"], textCn("rs-h5"))}>
                {rule.title}
              </h5>
              <p className={textCn("rs-text-3")}>{rule.description}</p>
            </div>
          ))}
        </div>
      </div>
    </ThemeProvider>
  );
}
