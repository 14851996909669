export const goalOptions = [
  { label: "Performance boost", value: "Performance boost" },
  {
    label: "Project completion/initiation",
    value: "Project completion/initiation",
  },
  { label: "Specific team challenge", value: "Specific team challenge" },
  { label: "Skill enhancement", value: "Skill enhancement" },
  { label: "Other", value: "Other" },
];

export const locationOptions = [
  { label: "No preference", value: "No preference" },
  {
    label: "Existing office location",
    value: "Existing office location",
  },
  { label: "External venue", value: "External venue" },
];
