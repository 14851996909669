import React from "react";
import classNames from "classnames";
import { Route, Routes } from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Intro from "./pages/intro/intro";
import Catalog from "./pages/catalog/catalog";
import Camp from "./pages/camp/camp";
import Form from "./pages/from/form";
import Success from "./pages/success/success";
import Instructions from "./pages/instructions/instructions";

import s from "./app.module.scss";

export default function App() {
  return (
    <div className={classNames(s.app, "container")}>
      <Header />
      <div className={s.main}>
        <Routes>
          <Route path="/" element={<Intro />} />
          <Route path="/index.html" element={<Intro />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/camp" element={<Camp />} />
          <Route path="/form" element={<Form />} />
          <Route path="/success" element={<Success />} />
          <Route path="/instructions" element={<Instructions />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}
