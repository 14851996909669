import React, { useMemo } from "react";
import classNames from "classnames";
import { createTextCn } from "@rescui/typography";
import { useReservationFormContext } from "../../contexts/reservationFormContext";
import { Button } from "@rescui/button";
import { Link, useSearchParams } from "react-router-dom";
import { ThemeProvider } from "@rescui/ui-contexts";

import catalog from "../../data.json";

import s from "./success.module.scss";

const textCn = createTextCn("dark");

export default function Success() {
  const { data: formData } = useReservationFormContext();
  const [searchParams] = useSearchParams();
  const issueId = searchParams.get("issueId");
  const from = useMemo(() => new Date(formData.from || ""), [formData]);
  const to = useMemo(() => new Date(formData.to || ""), [formData]);

  const camp = useMemo(
    () => catalog.find((el) => el.id === Number(formData.campId)),
    [formData],
  );

  return (
    <>
      <ThemeProvider theme="dark">
        <div className={s.subheader}>
          <h1 className={classNames(textCn("rs-h1"), s["subheader-item"])}>
            {`${formData.location}, `}
            {formData.from && `${from.getDate()}.${from.getMonth()}`}
            {formData.from && formData.to && " - "}
            {formData.to && `${to.getDate()}.${to.getMonth()}, `}
            {`${formData.teamName} Team`}
          </h1>
          <p className={classNames(textCn("rs-text-1"), s["subheader-item"])}>
            Thank you, your form is submitted!
          </p>
          <Link
            to={`https://youtrack.jetbrains.com/issue/${issueId}`}
            target="_blank"
          >
            <Button mode="outline">Check in YouTrack</Button>
          </Link>
        </div>
      </ThemeProvider>
      <div
        className={classNames(s.success, "container")}
        style={{ backgroundImage: `url('./camps/${camp?.images[0]}.jpeg')` }}
      ></div>
    </>
  );
}
