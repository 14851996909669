import React, { LegacyRef, MouseEventHandler } from "react";
import cn from "classnames";
import { useTextStyles } from "@rescui/typography";
import { useGlowHover } from "@rescui/use-glow-hover";
import { presets, Tag } from "@rescui/tag";
import { Link } from "react-router-dom";

import s from "./card.module.scss";

interface CardProps {
  id: number;
  name: string;
  image: string;
  minCapacity?: string;
  maxCapacity?: string;
  onVirtualClick?: MouseEventHandler<HTMLAnchorElement>;
}

export default function Card({
  id,
  name,
  image,
  minCapacity,
  maxCapacity,
  onVirtualClick,
}: CardProps) {
  const textCn = useTextStyles();
  const glowRef = useGlowHover({
    lightColor: "rgba(154, 112, 193, 0.4)",
    enableBurst: true,
  });

  return (
    <Link
      to={`/camp?id=${id}`}
      className={s.card}
      style={{ backgroundImage: `url('./camps/${image}.jpeg')` }}
      onClick={onVirtualClick}
    >
      <div
        className={s.info}
        ref={glowRef as LegacyRef<HTMLDivElement> | undefined}
      >
        <h3 className={cn(textCn("rs-h3"), s.title)}>{name}</h3>
        {(minCapacity || maxCapacity) && (
          <Tag {...presets.rockDark}>
            {`${minCapacity}`}
            {maxCapacity && " - "}
            {maxCapacity && `${maxCapacity}`}
            {" people"}
          </Tag>
        )}
      </div>
    </Link>
  );
}
