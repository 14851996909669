import React, {useEffect, useState} from "react";
import { Input } from "@rescui/input";

// @ts-ignore
export const StatefulInput = ({ enableClear, valueCallback, ...props }) => {
  const [value, setValue] = useState(props.value ?? "");

  useEffect(() => {
    valueCallback(value)
  }, [value, valueCallback]);

  return (
    <Input
      value={value}
      // @ts-ignore
      onInput={(e) => setValue(e.target.value)}
      onClear={enableClear ? () => setValue("") : undefined}
      {...props}
    />
  );
};
