import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./app";

import "@rescui/colors/lib/index.css";
import "./index.css";
import {ReservationFormProvider} from "./contexts/reservationFormContext";
import {PrincipalProvider} from "./contexts/principalContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ReservationFormProvider>
        <PrincipalProvider>
          <App />
        </PrincipalProvider>
      </ReservationFormProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
