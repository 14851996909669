import React, { useEffect, useMemo, useState } from "react";
import { ThemeProvider } from "@rescui/ui-contexts";
import { Button } from "@rescui/button";
import { AddOutlineIcon, ArrowTopRightIcon, InfoIcon } from "@rescui/icons";
import { createTextCn } from "@rescui/typography";
import { Switcher } from "@rescui/switcher";
import classNames from "classnames";
import { Select } from "@rescui/select";
import { SelectOption } from "@rescui/select/lib/types";
import { Tooltip } from "@rescui/tooltip";
import { goalOptions, locationOptions } from "./data";
import { StatefulInput } from "./stateful-input";
import { Textarea } from "@rescui/textarea";
import { useReservationFormContext } from "../../contexts/reservationFormContext";
import Pagination from "../../components/pagination/pagination";
import { useNavigate } from "react-router-dom";
import { TeamMember } from "../../types";
import { usePrincipalContext } from "../../contexts/principalContext";

import s from "./form.module.scss";

const textCn = createTextCn("dark");

const today = new Date();
const tomorrow = new Date(today.setDate(today.getDate() + 2))
  .toISOString()
  .split("T")[0];

function participantsToSize(participants: TeamMember[]) {
  if (participants.length < 10) {
    return "1 - 10";
  } else if (participants.length < 20) {
    return "10 - 20";
  } else if (participants.length < 40) {
    return "20 - 40";
  } else {
    return "40+";
  }
}

type TeamMemberState = TeamMember & { nameError: boolean; locationError: boolean };

export default function Form() {
  const { fullName } = usePrincipalContext();
  const header = useMemo(() => {
    if (fullName) {
      return `Hi ${fullName}! Please fill in the form`;
    }

    return "Please fill in the form";
  }, [fullName]);
  const navigate = useNavigate();
  const { data, setData } = useReservationFormContext();
  const [goal, setGoal] = useState<SelectOption>(() => {
    if (data.goal) {
      return (
        goalOptions.find((option) => option.value === data.goal) ??
        goalOptions[0]
      );
    }
    return goalOptions[0];
  });
  const [location, setLocation] = useState<SelectOption>(() => {
    if (data.location) {
      return (
        locationOptions.find((option) => option.value === data.location) ??
        locationOptions[0]
      );
    }
    return locationOptions[0];
  });
  const [teamName, setTeamName] = useState(data.teamName ?? "");
  const [teamNameError, setTeamNameError] = useState(false);
  const [description, setDescription] = useState(data.description ?? "");
  const [descriptionError, setDescriptionError] = useState(false);
  const [experience, setExperience] = useState(data.experience ?? "");
  const [startDate, setStartDate] = useState(data.from ?? "");
  const [endDate, setEndDate] = useState(data.to ?? "");
  const [participants, setParticipants] = useState<TeamMemberState[]>(() => {
    if (data.team) {
      return data.team.map((member) => ({
        ...member,
        nameError: false,
        locationError: false,
      }));
    }
    return [
      { name: "", location: "", nameError: false, locationError: false },
      { name: "", location: "", nameError: false, locationError: false },
      { name: "", location: "", nameError: false, locationError: false },
    ];
  });
  const [teamSize, setTeamSize] = useState<string>(() =>
    participantsToSize(participants),
  );

  const saveState = (e: any) => {
    e.preventDefault();

    const errors = participants
        // get all members with empty name or location
        .filter(({ name, location }) => !name || !location)
        // filter members with empty name and location
        .filter(({ name, location }) => Boolean(name) || Boolean(location))
        // set error for each member
        .map((member, index) => {
          setParticipants((prevState) => {
            const newState = [...prevState];
            newState[index].nameError = !Boolean(member.name);
            newState[index].locationError = !Boolean(member.location);
            return newState;
          });
          return member;
        }).length;

    if (!teamName || !description || errors > 0) {
      setTeamNameError(!Boolean(teamName));
      setDescriptionError(!Boolean(description));
      return;
    }

    const formData = {
      teamName,
      goal: goal.value,
      location: location.value,
      from: startDate,
      to: endDate,
      description,
      experience,
      team: participants.filter(({ name, location }) => name && location),
    };
    console.log(formData);
    setData(formData);
    navigate("/catalog");
  };

  useEffect(() => {
    if (participantsToSize(participants) !== teamSize)
      setTeamSize(participantsToSize(participants));
  }, [participants, teamSize]);

  return (
    <ThemeProvider theme="dark">
      <div className={s.form}>
        <div className={s.subheader}>
          <Pagination page={1} className={s["subheader-block"]} />
          <p className={classNames(textCn("rs-h3"), s["subheader-block"])}>
            {header}
          </p>
          <div className={s["subheader-block"]}>
            <Button
              onClick={saveState}
              href="/catalog"
              icon={<ArrowTopRightIcon />}
              iconPosition="right"
              mode="rock"
            >
              Explore locations
            </Button>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.col}>
            <div className={s.el}>
              <h4 className={classNames(s.label, textCn("rs-text-2"))}>
                Size of the Team
              </h4>
              <Switcher
                value={teamSize}
                onChange={setTeamSize}
                options={[
                  { label: "1 - 10", value: "1 - 10" },
                  { label: "10 - 20", value: "10 - 20" },
                  { label: "20 - 40", value: "20 - 40" },
                  { label: "40+", value: "40+" },
                ]}
              />
            </div>
            <div className={s.el}>
              <StatefulInput
                enableClear={false}
                value={teamName}
                name="teamName"
                label="Your Team"
                placeholder="Team Name"
                valueCallback={setTeamName}
                note={
                  teamNameError && (
                    <span className={s.error}>Please fill in this field</span>
                  )
                }
                onFocus={() => setTeamNameError(false)}
              />
            </div>
            <div className={s.el}>
              <Textarea
                value={description}
                enableClear={false}
                name="description"
                label="Expected camp results"
                placeholder="Please describe what do you expect"
                minRows={3}
                onChange={(e) => setDescription(e.target.value)}
                note={
                  descriptionError && (
                    <span className={s.error}>Please fill in this field</span>
                  )
                }
                onFocus={() => setDescriptionError(false)}
              />
            </div>
            <div className={s.el}>
              <Textarea
                enableClear={false}
                value={experience}
                name="experience"
                label={
                  <>
                    Previous Camp Experience:{" "}
                    <Tooltip
                      placement="right"
                      content="Were you participating in a team event last time? Have you or any of your colleagues participated in a similar camp or retreat before? If yes, please share brief insights or lessons from that experience. Insert a link to the previous event report"
                    >
                      <InfoIcon style={{ verticalAlign: "top" }} />
                    </Tooltip>
                  </>
                }
                placeholder="Please describe your experience "
                minRows={3}
                onChange={(e) => setExperience(e.target.value)}
              />
            </div>
          </div>
          <div className={s.col}>
            <div className={classNames(s.el, s.date)}>
              <StatefulInput
                enableClear={false}
                value={startDate}
                name="startDate"
                label="Dates"
                type="date"
                valueCallback={setStartDate}
                min={tomorrow}
                max={endDate || undefined}
              />
              <StatefulInput
                enableClear={false}
                value={endDate}
                name="endDate"
                type="date"
                valueCallback={setEndDate}
                min={startDate || tomorrow}
              />
            </div>
            <div className={s.el}>
              <Select
                options={locationOptions}
                value={location}
                onChange={(value) => setLocation(value)}
                size="m"
                name="location"
                label="Preferred Location"
              />
            </div>
            <div className={s.el}>
              <Select
                options={goalOptions}
                value={goal}
                onChange={(value) => setGoal(value)}
                size="m"
                name="goal"
                label="Your Purpose"
              />
            </div>
          </div>
        </div>
        <div className={classNames(s.row, s.participant)}>
          {participants.map(({ name, location, nameError, locationError }, index) => (
            <div className={s.col}>
              <StatefulInput
                enableClear={false}
                value={name}
                name={`participant-name-${index}`}
                label={`Participant ${index + 1}`}
                placeholder="Participant’s Name"
                valueCallback={(newValue: string) =>
                  setParticipants((prevState) => {
                    if (prevState[index].name !== newValue) {
                      const newState = [...prevState];
                      newState[index].name = newValue;
                      return newState;
                    } else {
                      return prevState;
                    }
                  })
                }
                note={
                  nameError && (
                    <span className={s.error}>Please fill in the name</span>
                  )
                }
                onFocus={() => {
                  setParticipants((prevState) => {
                    if (prevState[index].nameError) {
                      const newState = [...prevState];
                      newState[index].nameError = false;
                      return newState;
                    } else {
                      return prevState;
                    }
                  });
                }}
              />
              <StatefulInput
                enableClear={false}
                value={location}
                name={`participant-location-${index}`}
                placeholder="Participant’s Location"
                className={s.input}
                valueCallback={(newValue: string) =>
                  setParticipants((prevState) => {
                    if (prevState[index].location !== newValue) {
                      const newState = [...prevState];
                      newState[index].location = newValue;
                      return newState;
                    } else {
                      return prevState;
                    }
                  })
                }
                note={
                    locationError && (
                        <span className={s.error}>Please fill in the location</span>
                    )
                }
                onFocus={() => {
                  setParticipants((prevState) => {
                    if (prevState[index].locationError) {
                      const newState = [...prevState];
                      newState[index].locationError = false;
                      return newState;
                    } else {
                      return prevState;
                    }
                  });
                }}
              />
            </div>
          ))}
          <div className={s.el}>
            <h4 className={textCn("rs-text-2")}>{`Participant ${
              participants.length + 1
            }`}</h4>
            <Button
              icon={<AddOutlineIcon size="s" />}
              className={s.button}
              iconPosition="right"
              mode="outline"
              onClick={() =>
                setParticipants((prevState) => [
                  ...prevState,
                  { name: "", location: "", nameError: false, locationError: false },
                ])
              }
            >
              Add participant
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
