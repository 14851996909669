import React from "react";
import classNames from "classnames";
import { Button } from "@rescui/button";
import { ArrowTopRightIcon } from "@rescui/icons";

import s from "./intro.module.scss";

export default function Intro() {
  return (
    <div className={s.wrapper}>
      <div className={classNames(s.intro)}>
        <h1 className={s.title}>
          Book houses where you and your team can work together.
        </h1>
        <div className={s.controls}>
          {/* /api/login will always redirect back to /form */}
          <Button mode="rock" size="l" href="/api/login">
            Boost your productivity
          </Button>
          {/* when going directly to /form will not trigger authentication */}
          {/* TODO: don't forget to change it to /api/logn */}
          {/* this hack is here to support rapid local development */}
          <Button
            mode="rock"
            size="l"
            icon={<ArrowTopRightIcon />}
            className={s.button}
            href="/form"
          />
        </div>
      </div>
    </div>
  );
}
