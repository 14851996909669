import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTextStyles } from "@rescui/typography";
import classNames from "classnames";
import Pagination from "../../components/pagination/pagination";
import { Button } from "@rescui/button";
import { ThemeProvider } from "@rescui/ui-contexts";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowTopRightIcon,
  LoadingIcon,
} from "@rescui/icons";
import { useReservationFormContext } from "../../contexts/reservationFormContext";
import { presets, Tag } from "@rescui/tag";

import data from "../../data.json";

import s from "./camp.module.scss";

export default function Camp() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const textCn = useTextStyles();
  const id = Number(searchParams.get("id"));

  const camp = useMemo(() => data.find((el) => el.id === id), [id]);

  const { data: formData, setData } = useReservationFormContext();
  const [loading, setLoading] = useState(false);
  const [slided, setSlided] = useState(false);

  const submitRequest = useCallback(async () => {
    if (loading || !camp) {
      return;
    }

    setLoading(true);
    const payload = {
      ...formData,
      campId: `${camp?.id}`,
    };
    setData(payload);
    try {
      const result = await fetch("/api/camp/reserve", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (result.status === 200) {
        const youTrackIssueId = await result.text();
        console.log(youTrackIssueId);
        navigate("/success?issueId=" + youTrackIssueId, { replace: true });
      } else {
        console.error((await result.text()) ?? result.statusText);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [formData, camp?.id]);

  return (
    <>
      <ThemeProvider theme="dark">
        <div className={s.subheader}>
          <Pagination page={3} className={s["subheader-block"]} />
          <p className={classNames(textCn("rs-h2"), s["subheader-block"])}>
            {camp?.name}
          </p>
          <div className={s["subheader-block"]}>
            <Button
              icon={loading ? <LoadingIcon /> : <ArrowTopRightIcon />}
              iconPosition="right"
              mode="rock"
              onClick={submitRequest}
            >
              {"Submit request"}
            </Button>
          </div>
        </div>
      </ThemeProvider>
      <div className={s.camp}>
        {camp ? (
          <>
            <div className={s.gallery}>
              <div className={classNames(s.images, slided && s.slided)}>
                {camp.images.map((image) => (
                  <div
                    className={s.image}
                    style={{
                      backgroundImage: `url('./camps/${image}.jpeg')`,
                    }}
                  />
                ))}
              </div>

              <div className={s.controls}>
                <div className={s.tags}>
                  <Tag
                    className={s.tag}
                    {...presets.outlineLight}
                    borderColor="rgba(25, 25, 28, 0.30)"
                  >
                    {`${camp.minCapacity} - ${camp.maxCapacity} people`}
                  </Tag>
                  {camp.tags.map((tag) => (
                    <Tag
                      className={s.tag}
                      key={tag}
                      {...presets.outlineLight}
                      borderColor="rgba(25, 25, 28, 0.30)"
                    >
                      {tag}
                    </Tag>
                  ))}
                </div>
                <div className={s.arrows}>
                  <Button
                    icon={<ArrowLeftIcon />}
                    mode="clear"
                    onClick={() => setSlided(false)}
                  />
                  {slided ? 2 : 1} / 2
                  <Button
                    icon={<ArrowRightIcon />}
                    mode="clear"
                    onClick={() => setSlided(true)}
                  />
                </div>
              </div>
            </div>
            <div className={s.info}>
              <div className={s.description}>
                <h3 className={classNames(s.title, textCn("rs-h3"))}>
                  Description
                </h3>
                <ul>
                  {camp.description.map((line, index) => (
                    <li key={index} className={textCn("rs-text-3")}>
                      {line}
                    </li>
                  ))}
                </ul>
              </div>
              <div className={s.address}>
                <h3 className={classNames(s.title, textCn("rs-h3"))}>
                  Address
                </h3>
                <p className={textCn("rs-text-3")}>{camp.address}</p>
              </div>
            </div>
          </>
        ) : (
          <p>There is no data</p>
        )}
      </div>
    </>
  );
}
