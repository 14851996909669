import React, {createContext, useEffect, useState} from "react";
import {ReservationRequest} from "../types";

type PartialReservationRequest = Partial<ReservationRequest>;

type ReservationFormContextType = {
    data: PartialReservationRequest,
    setData:  React.Dispatch<React.SetStateAction<PartialReservationRequest>>
}
const ReservationFormContext = createContext<ReservationFormContextType>({
    data: {},
    setData: () => {}
});

const {Provider} = ReservationFormContext;

interface ReservationFormProviderProps {
    children: React.ReactNode;
}
function ReservationFormProvider({children}: ReservationFormProviderProps) {
    const [data, setData] = useState<PartialReservationRequest>({});

    useEffect(() => {
        console.log("ReservationFormProvider: data changed", data);
    }, [data]);

    return <Provider value={{data, setData}}>{children}</Provider>;
}

const useReservationFormContext = () => React.useContext(ReservationFormContext);

export {
    ReservationFormProvider,
    useReservationFormContext
}
