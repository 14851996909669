import React from "react";
import classNames from "classnames";
import { createTextCn } from "@rescui/typography";
import { useLocation } from "react-router-dom";

import logo from "../../images/logo_rocket.svg";

import s from "./header.module.scss";

const textCn = createTextCn("dark");

export default function Header() {
  const location = useLocation();
  const isRounded =
    location.pathname === "/" || location.pathname === "/instructions";
  const isColored = location.pathname === "/success";

  return (
    <div
      className={classNames(
        s.header,
        "container",
        isRounded && s.rounded,
        isColored && s.colored,
      )}
    >
      <a href="/">
        <img src={logo} alt="Logo" />
      </a>
      <a href="/instructions" className={textCn("rs-link", { mode: "clear" })}>
        Instructions
      </a>
    </div>
  );
}
