import React, { useEffect, useMemo, useState } from "react";
import Card from "../../components/card/card";
import Pagination from "../../components/pagination/pagination";
import { Button } from "@rescui/button";
import { createTextCn } from "@rescui/typography";
import { ThemeProvider } from "@rescui/ui-contexts";
import { usePrincipalContext } from "../../contexts/principalContext";
import classNames from "classnames";

import initData from "../../data.json";

import s from "./catalog.module.scss";

const textCn = createTextCn("dark");

export default function Catalog() {
  const [data, setData] = useState(initData);
  const [officesOn, setOfficesOn] = useState(false);
  const [externalOn, setExternalOn] = useState(false);
  const [hahaOn, setHahaOn] = useState(false);

  const { username } = usePrincipalContext();
  const header = useMemo(() => {
    if (username) {
      return `${username}, Choose your perfect location`;
    }

    return "Choose your perfect location";
  }, [username]);

  useEffect(() => {
    setData(
      officesOn
        ? initData.filter((el) => el.office)
        : externalOn
        ? initData.filter((el) => !el.office)
        : initData,
    );
    setHahaOn(false);
  }, [officesOn, externalOn]);

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      setHahaOn(false);
    };

    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  return (
    <>
      <ThemeProvider theme="dark">
        <div className={s.subheader}>
          <Pagination page={2} className={s["subheader-block"]} />
          <p className={classNames(textCn("rs-h3"), s["subheader-block"])}>
            {header}
          </p>
          <div className={classNames(s.filters, s["subheader-block"])}>
            <Button
              mode={officesOn ? "rock" : "outline"}
              className={s.button}
              onClick={filterOfficeHandle}
            >
              Office locations
            </Button>

            <Button
              mode={externalOn ? "rock" : "outline"}
              onClick={filterExternalHandle}
            >
              External venues
            </Button>
          </div>
        </div>
      </ThemeProvider>
      <div className={s.catalog}>
        {data.map((camp) => (
          <Card
            id={camp.id}
            key={camp.id}
            name={camp.name}
            image={camp.images[0]}
            maxCapacity={camp.maxCapacity}
            minCapacity={camp.minCapacity}
            onVirtualClick={camp.virtual ? handleVirtualClick : undefined}
          />
        ))}
        {hahaOn && (
          <div className={s.haha} onClick={() => setHahaOn(false)}>
            <h1 className={textCn("rs-h1")}>Ha-ha, got you!</h1>
          </div>
        )}
      </div>
    </>
  );

  function filterOfficeHandle() {
    setOfficesOn(!officesOn);
    setExternalOn(false);
  }

  function filterExternalHandle() {
    setExternalOn(!externalOn);
    setOfficesOn(false);
  }

  // @ts-ignore
  function handleVirtualClick(e) {
    e.preventDefault();

    setHahaOn(true);
  }
}
