import React from "react";
import classNames from "classnames";
import { Button } from "@rescui/button";
import { Link } from "react-router-dom";

import s from "./pagination.module.scss";

interface PaginationProps {
  page: number;
  className?: string;
}

export default function Pagination({ page, className }: PaginationProps) {
  return (
    <div className={classNames(s.pagination, className)}>
      <Link to={"/form"} className={classNames(s.button)}>
        <Button className={classNames(page === 1 && s.active)} mode="outline">
          1
        </Button>
      </Link>
      <Link to={"/catalog"} className={classNames(s.button)}>
        <Button className={classNames(page === 2 && s.active)} mode="outline">
          2
        </Button>
      </Link>

      <Button
        className={classNames(s.button, page === 3 && s.active)}
        mode="outline"
        disabled={page !== 3}
      >
        3
      </Button>
    </div>
  );
}
